<template>
    <div id="contact-edit">
        <h2 is="sui-header" class="dividing">Edit Contact - ID {{$route.params.id}}</h2>

        <sui-form :loading="loading" :error="errorTitle !== ''" :success="successTitle !== ''">
            <success-message :title="successTitle" :reasons="successList"></success-message>
            <error-message :title="errorTitle" :reasons="errorList"></error-message>
            <contact-form :contact="contact"></contact-form>

            <sui-divider></sui-divider>

            <sui-button type="submit" color="blue" @click.prevent="update">Update</sui-button>
            <sui-button floated="right" @click.prevent="$router.push('/dashboard/contact')">Cancel</sui-button>
        </sui-form>
    </div>
</template>

<script>


    import axios from "axios";
    import ContactForm from "@/views/dashboard/contact/ContactForm";

    import SuccessMessage from "@/components/SuccessMessage";
    import ErrorMessage from "@/components/ErrorMessage";

    export default {
        name: "ContactEdit",
        components: {SuccessMessage, ErrorMessage, ContactForm},
        data: function () {
            return {
                contact: {
                    location: "",
                    contactType: "",
                    title: "",
                    contactNumber: "",

                    description: "",

                    errorTitle: false,
                    errorLocation: false,
                    errorContactType: false,
                },
                loading: true,

                errorTitle: "",
                errorList: [],

                successTitle: "",
                successList: [],
            }
        },
        created: async function(){
            axios({
                    method: "POST",
                    url: "/api/contact/get",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),
                        id: this.$route.params.id
                    }
            }).then((response)=>{
                console.log(response)
                this.loading = false
                this.contact = {
                    location: response.data.contact.location,
                    contactType: response.data.contact.contactType,
                    title: response.data.contact.title,
                    contactNumber: response.data.contact.contactNumber,

                    description: response.data.contact.description,

                    errorTitle: false,
                    errorLocation: false,
                    errorContactType: false,}
            }).catch((err) => {

                this.errorTitle = "Cannot load user profile";
                this.errorList = [err];
                this.errorList.push("Network connection issue.");
            })
        },
        methods: {
            update: function(){
                this.loading = true
                axios({
                    method: "POST",
                    url: "/api/contact/update",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        id: this.$route.params.id,

                    location: this.contact.location,
                    contactType: this.contact.contactType,
                    title: this.contact.title,
                    contactNumber: this.contact.contactNumber,

                    description: this.contact.description,
                    }
                }).then(() => {
                    this.successTitle = "User Profile Updated!"
                }).catch(() => {
                    this.errorTitle = "Cannot update user profile";
                    this.errorList = [];
                    this.errorList.push("Network connection issue.");
                }).finally(() => {
                    this.loading = false
                })

            }
        }
    }
</script>

<style scoped>

</style>
