<template>
    <div>
        <sui-form-field required :error="contact.errorTitle">
            <label>Title</label>
            <input type="text" name="contact-title" placeholder="Title" v-model="contact.title">
        </sui-form-field>
        <sui-form-field required :error="contact.errorLocation">
            <label>Location</label>
            <sui-dropdown
                    fluid
                    :options="locationOptions"
                    placeholder="Select Location"
                    search
                    selection

                    v-model="contact.location"
            />
        </sui-form-field>
        <sui-form-field required :error="contact.errorContactType">
            <label>Contact Type</label>
            <sui-dropdown
                    fluid
                    :options="contactTypeOptions"
                    placeholder="Select Contact Type"
                    search
                    selection
                    v-model="contact.contactType"
            />
        </sui-form-field>
        <sui-form-field>
            <label>Contact Number</label>
            <input type="text" name="contact-number" placeholder="+1 XXX-XXX-XXXX" v-model="contact.contactNumber">
        </sui-form-field>
        <sui-form-field>
            <label>Description</label>
            <textarea type="text" name="contact-description" v-model="contact.description"></textarea>
        </sui-form-field>
    </div>
</template>

<script>
    export default {
        name: "ContactForm",
        props: {
            contact: {
                location: String,
                contactType: String,
                title: String,
                contactNumber: String,

                description: String,

                errorTitle: Boolean,
                errorLocation: Boolean,
                errorContactType: Boolean,
            },


        },
        data: function () {
            let locations = ['CNTower', 'Behan', 'Vector']// should be load remotely
            const contact_type_options = ['', 'Public Service', 'Team', 'Elevator Service', 'Building Plan']

            return {
                locationOptions: locations.map((i) => {
                    return {
                        value: i,
                        text: i,
                    }
                }),
                contactTypeOptions: contact_type_options.map((i) => {
                    return {
                        text: i,
                        value: i
                    }
                }),
            }
        }
    }
</script>

<style scoped>

</style>